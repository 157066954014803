import Vue, { computed, ref } from 'vue'
import VueI18n from '@/i18n'
import GameService from '@/services/game.service'
import store from '@/store'
import { getPublicChannel, unsubscribePublicChannel } from '@/ably'
import router from '@/router'
import Swal from 'sweetalert2'
import { StopwatchService } from '@/services/stopwatch/stopwatch.service'
import { uuidv4 } from '@/lib/uuid'

const addDebtEntity = ref(null)
const addDebtEntityTeam = ref(null)
const addDebtEntityType = ref(null)
const keyboardShortcuts = ref(true)
const stopwatchChannel = ref(null)
const activeStopwatch = ref(null)
const timer = ref(null)
const id = ref(null)
const autoActions = ref({
  homeTeam: {},
  awayTeam: {}
})
const clickedButton = ref('')
const selectedPlayerHome = ref('')
const selectedPlayerAway = ref('')
const log = ref({
  on: '',
  teammate: '',
  against: ''
})

const playerPopupBodyMaxHeight = ref(false)
const playerActionsScroll = ref(false)
const countdownLoaded = ref(false)

const timerTime = ref(null)
const teamOn = ref('')
const stage = ref('on')
const type = ref('')
const relatedTo = ref('')
const teamSelect = ref(null)
const playerSelect = ref('')
const playerSelection = ref(false)
const isTouchHold = ref(false)
const isTimeEditing = ref(false)
const ablyChannel = ref(null)
const showMobileTeamActionsPopup = ref(false)
const showMobilePlayerActionsPopup = ref(false)
const playerContextMenu = ref(null)
const teamContextMenu = ref(null)

export function useLive () {
  const isMobile = computed(() => false)
  const model = computed(() => store.state.main.model)
  const config = computed(() => store.state.config)
  const configAutoActions = computed(() => config.value.live.autoActions ?? [])
  const notifications = computed(() => config.value.live.notifications)
  const homeTeamOrderClass = computed(() => {
    if (reversedTeams.value) {
      if (store.state.main.actionsPosition === '3') {
        return 'order-2'
      } else {
        return 'order-3'
      }
    } else {
      if (store.state.main.actionsPosition === '1') {
        return 'order-2'
      } else {
        return 'order-1'
      }
    }
  })

  const actionsOrderClass = computed(() => `order-${store.state.main.actionsPosition}`)
  const awayTeamOrderClass = computed(() => {
    if (store.state.main.reversedTeams) {
      if (store.state.main.actionsPosition === '1') {
        return 'order-2'
      } else {
        return 'order-1'
      }
    } else {
      if (store.state.main.actionsPosition === '3') {
        return 'order-2'
      } else {
        return 'order-3'
      }
    }
  })

  const clientId = computed(() => store.state.id)

  const actionsPosition = computed(() => store.state.main.actionsPosition)
  const reversedTeams = computed(() => store.state.main.reversedTeams)
  const invertedTable = computed(() => store.state.main.invertedTable)
  const autoUnselect = computed(() => store.state.main.autoUnselect)

  const score = computed(() => {
    if (reversedTeams.value) {
      return `${model.value.score.away} : ${model.value.score.home}`
    }

    return `${model.value.score.home} : ${model.value.score.away}`
  })

  const scoreByPeriod = computed(() => {
    let score = ''
    model.value.scoreByPeriod.forEach((a, b) => {
      const separator = b === 0 ? '' : ' | '
      if (reversedTeams.value) {
        score = score.concat(separator, `${a.away} : ${a.home}`)
      } else {
        score = score.concat(separator, `${a.home} : ${a.away}`)
      }
    })

    return score
  })

  const playerPreview = computed(() => {
    let player = null
    if (playerSelect.value && teamSelect.value === 'home') {
      player = model.value.homeTeam.players.find((player) => player.number_of_jersey === playerSelect.value)
    }
    if (playerSelect.value && teamSelect.value === 'away') {
      player = model.value.awayTeam.players.find((player) => player.number_of_jersey === playerSelect.value)
    }

    return player ? player.name : '#'
  })

  const buttonPlayerGroups = computed(() => config.value.logs.player)
  const buttonTeamGroups = computed(() => config.value.logs.team)
  const teamActions = computed(() => {
    const actions = {}
    Object.keys(config.value.logs.team).forEach((stat) => {
      actions[stat] = config.value.logs.team[stat]
    })
    return actions
  })

  const teamHotKeys = computed(() => {
    const hotKeys = []
    Object.keys(teamActions.value).forEach((action) => {
      if (teamActions.value[action].hotKey) {
        hotKeys.push({
          code: teamActions.value[action].hotKey.code,
          type: action,
          shiftKey: Boolean(playerActions.value[action].hotKey.shiftKey)
        })
      }
    })

    return hotKeys
  })

  const playerActions = computed(() => {
    const actions = {}
    const arrayGroups = Object.keys(config.value.logs.player)
    arrayGroups.forEach((group) => {
      Object.keys(config.value.logs.player[group]).forEach((stat) => {
        actions[stat] = config.value.logs.player[group][stat]
      })
    })
    return actions
  })

  const playerHotKeys = computed(() => {
    const hotKeys = []
    Object.keys(playerActions.value).forEach((action) => {
      if (playerActions.value[action].hotKey) {
        hotKeys.push({
          code: playerActions.value[action].hotKey.code,
          type: action,
          shiftKey: Boolean(playerActions.value[action].hotKey.shiftKey)
        })
      }
    })

    return hotKeys
  })

  const buttonPlayerTypes = computed(() => Object.keys(config.value.logs.player))
  const buttonTeamTypes = computed(() => Object.keys(config.value.logs.team))
  const doneDisable = computed(() => !(log.value.on !== '' && type.value !== ''))
  const disableAway = computed(() => {
    if (relatedTo.value !== 'player' && relatedTo.value !== '') {
      return true
    }
    if (teamOn.value === 'away') {
      if (stage.value === 'against') {
        return true
      }
    }
    if (teamOn.value === 'home') {
      if (stage.value === 'teammate') {
        return true
      }
    }
    return false
  })

  const disableHome = computed(() => {
    if (relatedTo.value !== 'player' && relatedTo.value !== '') {
      return true
    }
    if (teamOn.value === 'home') {
      if (stage.value === 'against') {
        return true
      }
    }
    if (teamOn.value === 'away') {
      if (stage.value === 'teammate') {
        return true
      }
    }
    return false
  })

  const teamUuid = computed(() => {
    if (teamOn.value === 'home') {
      return model.value.homeTeam.uuid
    } else if (teamOn.value === 'away') {
      return model.value.awayTeam.uuid
    }

    return null
  })

  const teamAgainstUuid = computed(() => {
    if (teamOn.value === 'away') {
      return model.value.homeTeam.uuid
    } else if (teamOn.value === 'home') {
      return model.value.awayTeam.uuid
    }

    return null
  })

  const homeLabel = computed(() => {
    if (stage.value === 'on') {
      if (teamOn.value !== '') {
        return {
          text: 'select_action',
          bg: 'info'
        }
      } else {
        return {
          text: 'select_player',
          bg: 'success'
        }
      }
    }
    if (teamOn.value === 'home') {
      if (stage.value === 'teammate') {
        return {
          text: getStateStatType(),
          bg: 'info'
        }
      } else {
        return {
          text: 'disabled',
          bg: 'disabled'
        }
      }
    } else {
      if (stage.value === 'against') {
        return {
          text: getStateStatType(),
          bg: 'warning'
        }
      } else {
        return {
          text: 'disabled',
          bg: 'disabled'
        }
      }
    }
  })

  const awayLabel = computed(() => {
    if (stage.value === 'on') {
      if (teamOn.value !== '') {
        return {
          text: 'select_action',
          bg: 'info'
        }
      } else {
        return {
          text: 'select_player',
          bg: 'success'
        }
      }
    }
    if (teamOn.value === 'away') {
      if (stage.value === 'teammate') {
        return {
          text: getStateStatType(),
          bg: 'info'
        }
      } else {
        return {
          text: 'disabled',
          bg: 'disabled'
        }
      }
    } else {
      if (stage.value === 'against') {
        return {
          text: getStateStatType(),
          bg: 'warning'
        }
      } else {
        return {
          text: 'disabled',
          bg: 'disabled'
        }
      }
    }
  })

  function saveTimeToTimerApi (data) {
    if (!activeStopwatch.value) {
      StopwatchService.setTime(clientId.value, model.value.uuid, data)
    }
  }
  // Methods
  function setTime (data) {
    stopwatchChannel.value.publish('update_time', data)
  }
  function translateStat (stat, type) {
    return VueI18n.t(`sports.${config.value.sport}.stats.${stat}.${type}`)
  }

  function changePlayerPosition (action) {
    autoActions.value[addDebtEntityTeam.value][action.id] = addDebtEntity.value.uuid
    localStorage.setItem(`${addDebtEntityTeam.value}-${action.id}`, addDebtEntity.value.uuid)
  }
  function teamEntityClicked (event, team, entity) {
    event?.preventDefault()
    addDebtEntityTeam.value = team
    addDebtEntity.value = entity
    addDebtEntityType.value = 'team'
    this.$modal.show('entity-modal')
  }

  function playerEntityClicked (event, team, entity) {
    event?.preventDefault()
    addDebtEntityTeam.value = team
    addDebtEntity.value = entity
    addDebtEntityType.value = 'player'
    this.$modal.show('entity-modal')
  }
  function autoActionLabel (team, player) {
    const actions = []

    Object.keys(autoActions.value[team]).forEach((action) => {
      if (autoActions.value[team][action] === player.uuid) {
        actions.push(action)
      }
    })

    if (actions.length) {
      return actions.toString()
    }

    return null
  }
  function autoActionColor (team, player) {
    let color = ''

    Object.keys(autoActions.value[team]).forEach((action) => {
      if (autoActions.value[team][action] === player.uuid) {
        const a = config.value.live.autoActions.find((a) => a.id === action)
        color = a.color
      }
    })

    return color
  }

  function gameLogColor (gameLog) {
    console.log(gameLog)
    if (gameLog.relatedTo === 'player') {
      console.log(playerActions.value)
      return playerActions.value[gameLog.type]?.bgColor ?? '#70b9eb'
    } else {
      return '#70b9eb'
    }
  }
  async function getCurrentTimeFromTimerApi () {
    return StopwatchService.getTime(clientId.value, model.value.uuid)
  }
  function timerChange (data) {
    if (!activeStopwatch.value) {
      setTime(data)
      saveTimeToTimerApi(data)
    }
  }
  function toggleTime () {
    if (activeStopwatch.value) {
      return
    }

    // TODO: refs
    const timerRef = timer.value

    if (timerRef) {
      if (timerRef.isRunning) {
        timerRef.stopTimer()
      } else {
        timerRef.startTimer()
      }
    }
  }
  function translateLog (gameLog) {
    if (gameLog.against === null && gameLog.teammate === null) {
      return VueI18n.t('live.sports.' + config.value.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.alone', [gameLog.on])
    } else {
      if (gameLog.against !== null) {
        return VueI18n.t('live.sports.' + config.value.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.against', [gameLog.on, gameLog.against])
      } else if (gameLog.teammate !== null) {
        return VueI18n.t('live.sports.' + config.value.sport + '.logs.' + gameLog.relatedTo + '.' + gameLog.type + '.teammate', [gameLog.on, gameLog.teammate])
      }
    }
  }
  const keyDownListener = (event) => {
    if (!keyboardShortcuts.value) return

    if (event.code === 'Space') {
      event.preventDefault()
      toggleTime()
    }

    if (event.ctrlKey && event.code === 'KeyZ') {
      event.preventDefault()
      if (model.value.logs.length) {
        deleteLog(model.value.logs[0].uuid)
      }
    }

    if (relatedTo.value === 'player') {
      const playerHotKey = playerHotKeys.value.find((key) => {
        if (event.shiftKey === true) {
          return (key.code === event.code) && key.shiftKey
        } else {
          return key.code === event.code && !key.shiftKey
        }
      })

      if (playerHotKey && log.value.on) {
        event.preventDefault()

        selectType(playerHotKey.type)
        clickTypeButton(playerHotKey.type)
      }
    } else if (relatedTo.value === 'team') {
      const teamHotKey = teamHotKeys.value.find((key) => {
        if (event.shiftKey === true) {
          return (key.code === event.code) && key.shiftKey
        } else {
          return key.code === event.code && !key.shiftKey
        }
      })

      if (teamHotKey && log.value.on && relatedTo.value === 'team') {
        event.preventDefault()

        selectType(teamHotKey.type)
        clickTypeButton(teamHotKey.type)
      }
    }
  }
  function clickTypeButton (type) {
    clickedButton.value = type
    setTimeout(() => {
      clickedButton.value = ''
    }, 100)
  }

  function showPlayerActionsPopup () {
    setTimeout(() => {
      triggerPlayerActionsPopup()
    }, 100)
  }

  function triggerPlayerActionsPopup () {
    showMobilePlayerActionsPopup.value = true

    if (isMobile.value) {
      setTimeout(() => {
        const playerStats = document.getElementById('playerStats')
        const playerPopupBody = document.getElementById('playerPopupBody')

        const playerPopupTitle = document.getElementById('playerPopupTitle')
        const playerPopupClose = document.getElementById('playerPopupClose')
        const playerPopup = document.getElementById('playerPopup')

        const playerStatsHeight = playerStats.clientHeight
        const playerPopupBodyHeight = playerPopupBody.clientHeight

        const playerPopupHeight = playerPopup.clientHeight
        const playerPopupTitleHeight = playerPopupTitle.clientHeight
        const playerPopupCloseHeight = playerPopupClose.clientHeight

        playerActionsScroll.value = playerPopupBodyHeight - playerStatsHeight

        playerPopupBodyMaxHeight.value = playerPopupHeight - playerPopupTitleHeight - playerPopupCloseHeight - 16
      })
    }
  }

  function selectEntity (related, team, uuid) {
    if (!uuid) {
      resetLog()
      return
    }
    relatedTo.value = related
    if (relatedTo.value === 'game') {
      resetLog()
      // TODO: add game logs
    } else if (relatedTo.value === 'team') {
      if (log.value.on === uuid) {
        resetLog()
      } else {
        resetLog(related)
        teamOn.value = team
        log.value.on = uuid
        showMobileTeamActionsPopup.value = true
      }
    } else if (relatedTo.value === 'player') {
      if ((disableAway.value && team === 'away') || (disableHome.value && team === 'home')) {
        if (teamOn.value === team && stage.value === 'against') {
          resetLog('player')
          log.value.on = uuid
          type.value = ''
          teamOn.value = team
        }

        if (teamOn.value !== team && stage.value === 'teammate') {
          resetLog('player')
          log.value.on = uuid
          type.value = ''
          teamOn.value = team
        }
      } else {
        if (log.value.on === uuid) {
          resetLog()
        } else {
          if (log.value.teammate === uuid) {
            log.value.teammate = ''
            stage.value = 'teammate'
          } else if (log.value.against === uuid) {
            log.value.against = ''
            stage.value = 'against'
          } else {
            log.value[stage.value] = uuid
            if (stage.value === 'on') {
              type.value = ''
              teamOn.value = team
              showPlayerActionsPopup()
            } else {
              acceptLog()
            }
          }
        }
      }
    }
  }
  function selectType (button) {
    log.value.against = ''
    log.value.teammate = ''
    type.value = button
    if (relatedTo.value === 'team') {
      stage.value = 'on'
      acceptLog()
    }

    if (relatedTo.value === 'player') {
      if (playerActions.value[type.value].against) {
        stage.value = 'against'
      } else if (playerActions.value[type.value].teammate) {
        stage.value = 'teammate'
      } else {
        acceptLog()
      }
    }

    showMobileTeamActionsPopup.value = false
    showMobilePlayerActionsPopup.value = false
  }
  function acceptLog () {
    const timerRef = { ...timer.value }
    const data = { ...log.value }
    data.time = timerRef.diffTime
    data.type = type.value
    data.period = model.value.current_period
    data.team = teamUuid.value
    data.relatedTo = relatedTo.value
    GameService.gameLog(data)

    const actions = relatedTo.value === 'player' ? playerActions.value : teamActions.value

    if (actions[type.value].autoActions?.length) {
      actions[type.value].autoActions.forEach(action => {
        const team = action.teamAgainst ? (teamOn.value === 'home' ? 'awayTeam' : 'homeTeam') : (teamOn.value === 'home' ? 'homeTeam' : 'awayTeam')

        GameService.gameLog({
          relatedTo: 'player',
          period: model.value.current_period,
          team: action.teamAgainst ? teamAgainstUuid.value : teamUuid.value,
          type: action.action,
          time: timerRef.diffTime,
          on: autoActions.value[team][action.id]
        })
      })
    }

    type.value = ''
    stage.value = 'on'
    log.value.against = ''
    log.value.teammate = ''

    if (autoUnselect.value || isMobile.value) {
      resetLog()
    }
  }
  function gameUpdate (game, data) {
    model.value.logs.unshift(data.log)

    Vue.notify({
      group: 'logs',
      data: data.log,
      duration: 5000
    })

    model.value.score.home = game.home_team_score_total
    model.value.score.away = game.away_team_score_total
    model.value.scoreByPeriod = data.scoreByPeriod
    model.value.homeTeam.players = data.homePlayers
    model.value.awayTeam.players = data.awayPlayers
    model.value.homeTeam.period_stats = data.home_team_period_stats
    model.value.homeTeam.by_period_stats = data.home_team_by_period_stats
    model.value.awayTeam.period_stats = data.away_team_period_stats
    model.value.awayTeam.by_period_stats = data.away_team_by_period_stats

    if (notifications.value) {
      checkForNotifications(data.log)
    }
  }
  async function checkForNotifications (log) {
    const team = model.value.homeTeam.uuid === log.team ? model.value.homeTeam : model.value.awayTeam
    const teamAgainst = model.value.homeTeam.uuid === log.team ? model.value.awayTeam : model.value.homeTeam

    if (notifications?.value.player) {
      if (log.playerOn) {
        await checkPlayerNotifications(team, log.playerOn)
      }
      if (log.playerTeammate) {
        await checkPlayerNotifications(team, log.playerTeammate)
      }
      if (log.playerAgainst) {
        await checkPlayerNotifications(teamAgainst, log.playerAgainst)
      }
    }

    if (notifications?.value.team) {
      checkTeamNotifications(team, log)
    }
  }
  async function checkTeamNotifications (team, log) {
    const lastPeriodIndex = config.value.periods - 1
    const otPeriodIndex = config.value.periods
    const currentPeriodStatIndex = model.value.current_period === 0 ? otPeriodIndex : model.value.current_period - 1
    for (const rule of notifications.value.team.period) {
      let actionChangedStats = null
      if (log.relatedTo === 'player') {
        actionChangedStats = Object.keys(playerActions.value[log.type].on)
      } else if (log.relatedTo === 'team') {
        actionChangedStats = Object.keys(teamActions.value[log.type].on)
      }

      const intersection = rule.fields.filter(x => actionChangedStats.includes(x))

      if (!intersection.length) continue

      const teamStats = { ...team.by_period_stats[currentPeriodStatIndex] }

      if (model.value.current_period === 0) {
        const teamStatsLastPeriod = { ...team.by_period_stats[lastPeriodIndex] }
        Object.keys(teamStats).forEach((statKey) => {
          teamStats[statKey] = teamStats[statKey] + teamStatsLastPeriod[statKey]
        })
      }

      let sum = 0
      const fields = []
      rule.fields.forEach((field) => {
        sum += teamStats[field]
        fields.push({ name: field, value: teamStats[field] })
      })

      if (sum === rule.value) {
        let content = ''
        fields.forEach((f) => {
          content += `<li><strong>${translateStat(f.name, 'original')}: <span class="text-warning">${f.value}</span></strong></li>`
        })

        await Swal.fire({
          title: VueI18n.t(`live.notifications.team.period.${rule.name}`, { team: `${team.name}` }),
          icon: 'warning',
          html: `<ul>${content}</ul>`,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i>',
          confirmButtonAriaLabel: 'Ok!'
        })
      }
    }
  }
  async function checkPlayerNotifications (team, player) {
    const playerStat = team.players.find((p) => p.slug === player.slug)

    // total notifications
    for (const rule of notifications.value.player.total) {
      let sum = 0
      const fields = []
      rule.fields.forEach((field) => {
        sum += playerStat[field]
        fields.push({ name: field, value: playerStat[field] })
      })

      if (sum === rule.value) {
        let content = ''

        fields.forEach((f) => {
          content += `<li><strong>${translateStat(f.name, 'original')}: <span class="text-warning">${f.value}</span></strong></li>`
        })

        await Swal.fire({
          title: VueI18n.t(`live.notifications.player.total.${rule.name}`, { player: `#${playerStat.number_of_jersey} - ${player.name}` }),
          icon: 'warning',
          html: `<ul>${content}</ul>`,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i>',
          confirmButtonAriaLabel: 'Ok!'
        })
      }
    }

    return null
  }
  function deleteLog (uuid) {
    GameService.deleteLog(uuid)
  }
  function deleteGameLog (data, changedLogs) {
    const i = model.value.logs.map(item => item.uuid).indexOf(data.log.uuid) // find index of your object
    model.value.logs.splice(i, 1)
    Vue.notify({
      group: 'deletedLogs',
      data: data.log,
      duration: 3000
    })
    model.value.score = data.score
    model.value.scoreByPeriod = data.scoreByPeriod
    model.value.homeTeam.players = data.homePlayers
    model.value.awayTeam.players = data.awayPlayers
    model.value.homeTeam.period_stats = data.home_team_period_stats
    model.value.homeTeam.by_period_stats = data.home_team_by_period_stats
    model.value.awayTeam.period_stats = data.away_team_period_stats
    model.value.awayTeam.by_period_stats = data.away_team_by_period_stats

    if (changedLogs) {
      changedLogs.forEach((log) => {
        const l = model.value.logs.find((changeLog) => changeLog.uuid === log.uuid)
        l.home_team_score = log.home_team_score
        l.away_team_score = log.away_team_score
      })
    }
  }
  function stripHtmlTags (str) {
    if ((str === null) || (str === '')) {
      return ''
    } else {
      str = str.toString()
    }
    return str.replace(/<[^>]*>/g, '')
  }
  function deleteLastLog () {
    if (model.value.logs.length > 0) {
      deleteLog(model.value.logs[0].uuid)
    }
  }
  function resetLog (related = '') {
    showMobilePlayerActionsPopup.value = false
    showMobileTeamActionsPopup.value = false

    log.value = {
      on: '',
      teammate: '',
      against: ''
    }

    teamOn.value = ''
    stage.value = 'on'
    type.value = ''
    relatedTo.value = related
  }
  function getStateStatType () {
    return Object.keys(config.value.logs[relatedTo.value][type.value][stage.value])[0]
  }
  function setPeriod (period) {
    timer.value.stopTimer()
    store.commit('main/loading')
    GameService.setPeriod({ period: period })
  }
  function gameUpdatePeriod () {
    GameService.live().then(async (response) => {
      await store.dispatch('main/setModel', response.data)
      // TODO: refs
      if (!activeStopwatch.value) {
        timer.value.initTimeByPeriod()

        setTime({ time: timer.value.diffTime, isRunning: timer.value.isRunning, shotClock: 0, isShotClockUpdated: false })
      }

      setTimeout(() => {
        store.commit('main/loaded')
      })
    })
  }
  function setData (data) {
    model.value = data
  }
  function endGame () {
    store.commit('main/mobileSettings', false)
    Swal.fire({
      title: VueI18n.t('swal.endgame.title'),
      text: VueI18n.t('swal.endgame.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: VueI18n.t('swal.endgame.yes'),
      cancelButtonText: VueI18n.t('swal.endgame.no'),
      showCloseButton: true,
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value) {
        store.commit('main/loading')
        localStorage.removeItem(`timer.time.${model.value.uuid}`)
        GameService.endGame().then(() => {
          router.push({ name: 'archive' })
        })
      }
    })
  }
  function onDestroy () {
    window.removeEventListener('resize', screenCheck)
    window.removeEventListener('keydown', keyDownListener)
    unsubscribePublicChannel(`${clientId.value}.record.${model.value.uuid}`)
    unsubscribePublicChannel(`${clientId.value}.game.${model.value.uuid}.stopwatch`)
  }

  const updateTimeListener = (message) => {
    switch (message.name) {
      case 'update_time':
        timerTime.value = message?.data

        timer.value.diffTime = message?.data?.time

        if (message?.data?.isRunning) {
          timer.value.startTimer()
        } else {
          timer.value.stopTimer()
        }
        break
    }
  }
  function subscribeOnStopwatchChannel () {
    stopwatchChannel.value.subscribe(updateTimeListener)
  }

  function unsubscribeOnStopwatchChannel () {
    stopwatchChannel.value.unsubscribe(updateTimeListener)
  }

  function setActiveStopwatch (member) {
    removeActiveStopwatch()

    activeStopwatch.value = member

    if (timer.value) {
      timer.value.isEditMode = false
    }

    subscribeOnStopwatchChannel()
  }

  function removeActiveStopwatch () {
    ejectTimer()
    activeStopwatch.value = null
    unsubscribeOnStopwatchChannel()
  }

  function screenCheck () {
    if (window.innerWidth >= 600) {
      store.dispatch('main/setIsMobile', false)
    } else {
      store.dispatch('main/setIsMobile', true)
    }
  }

  function ejectTimer () {
    stopwatchChannel.value.publish('eject', activeStopwatch.value)
  }

  async function onInit () {
    screenCheck()
    id.value = uuidv4()

    window.addEventListener('resize', screenCheck)
    window.addEventListener('keydown', keyDownListener)

    console.log(configAutoActions.value)
    if (configAutoActions.value) {
      configAutoActions.value.forEach((action) => {
        const homeTeamAction = localStorage.getItem(`homeTeam-${action.id}`)
        const awayTeamAction = localStorage.getItem(`awayTeam-${action.id}`)

        if (homeTeamAction && model.value.homeTeam.players.find((p) => p.uuid === homeTeamAction)) {
          autoActions.value.homeTeam[action.id] = homeTeamAction
        } else {
          autoActions.value.homeTeam[action.id] = model.value.homeTeam.players[0].uuid
          localStorage.setItem(`homeTeam-${action.id}`, model.value.homeTeam.players[0].uuid)
        }

        if (awayTeamAction && model.value.awayTeam.players.find((p) => p.uuid === awayTeamAction)) {
          autoActions.value.awayTeam[action.id] = awayTeamAction
        } else {
          autoActions.value.awayTeam[action.id] = model.value.awayTeam.players[0].uuid
          localStorage.setItem(`awayTeam-${action.id}`, model.value.awayTeam.players[0].uuid)
        }
      })
    }

    /* Subscribe to presence enter events https://ably.com/docs/presence-occupancy/presence */

    stopwatchChannel.value = getPublicChannel(`${clientId.value}.game.${model.value.uuid}.stopwatch`)

    stopwatchChannel.value.presence.subscribe('enter', (member) => {
      if (member.data?.type === 'timer') {
        setActiveStopwatch(member)
      }
    })

    stopwatchChannel.value.presence.subscribe('leave', (member) => {
      if (member.clientId === activeStopwatch.value?.clientId) {
        removeActiveStopwatch()
      }
    })

    stopwatchChannel.value.presence.get((err, members) => {
      if (err) {
        return
      }

      const member = members.find((member) => member.data.type === 'timer')
      const scorekeeperMember = members.find((member) => member.data.type === 'scorekeeper' && member.clientId !== id.value)

      if (member) {
        setActiveStopwatch(member)
      } else if (scorekeeperMember) {
        setActiveStopwatch(scorekeeperMember)
      }
    })

    stopwatchChannel.value.presence.enterClient(id.value, { type: 'scorekeeper' })

    const channel = getPublicChannel(`${clientId.value}.record.${model.value.uuid}`)

    channel.subscribe((message) => {
      switch (message.name) {
        case 'update':
          if (message.data.add) {
            gameUpdate(message.data.game, message.data.resource)
          } else {
            deleteGameLog(message.data.resource, message.data.changedLogs)
          }
          break

        case 'update.period':
          gameUpdatePeriod()
          break
      }
    })

    const as = localStorage.getItem('autoUnselect')
    if (as === 'true' || as === 'false') {
      store.commit('main/autoUnselect', (as === 'true'))
    } else {
      store.commit('main/autoUnselect', config.value.live.auto_unselect)
    }

    countdownLoaded.value = false
    const currentTimeFromTimerApi = await getCurrentTimeFromTimerApi()

    if (currentTimeFromTimerApi?.data?.time) {
      timerTime.value = currentTimeFromTimerApi?.data
    } else {
      timerTime.value = { time: initialTimeForPeriod(), shotClock: 0, isRunning: false, isShotClockUpdated: false }
      setTime({ time: timerTime.value.time, shotClock: timerTime.value.shotClock, isRunning: false, isShotClockUpdated: false })
    }

    countdownLoaded.value = true
  }

  function initialTimeForPeriod () {
    if (config.value.time_reverse) {
      return model.value.schedule.periodTime
    } else {
      if (model.value.current_period === 0) {
        return config.value.periods * model.value.schedule.periodTime
      } else {
        return model.value.schedule.periodTime * model.value.current_period
      }
    }
  }

  function onEntityModalClosed () {
    keyboardShortcuts.value = true
  }
  function onEntityModalOpened () {
    keyboardShortcuts.value = false
  }

  return {
    onEntityModalOpened,
    onEntityModalClosed,

    // refs
    timer,
    teamContextMenu,
    playerContextMenu,
    // data
    keyboardShortcuts,
    addDebtEntity,
    addDebtEntityType,
    addDebtEntityTeam,
    playerActionsScroll,
    playerActions,
    playerPopupBodyMaxHeight,
    showMobileTeamActionsPopup,
    showMobilePlayerActionsPopup,
    countdownLoaded,
    isMobile,
    autoActions,
    clickedButton,
    selectedPlayerHome,
    selectedPlayerAway,
    log,
    timerTime,
    teamOn,
    stage,
    type,
    relatedTo,
    teamSelect,
    playerSelect,
    playerSelection,
    isTouchHold,
    isTimeEditing,
    ablyChannel,
    homeTeamOrderClass,
    actionsOrderClass,
    awayTeamOrderClass,
    clientId,
    actionsPosition,
    invertedTable,
    score,
    scoreByPeriod,
    playerPreview,
    buttonPlayerGroups,
    buttonTeamGroups,
    buttonPlayerTypes,
    buttonTeamTypes,
    doneDisable,
    homeLabel,
    awayLabel,
    model,
    config,
    reversedTeams,
    disableHome,
    disableAway,

    // method
    triggerPlayerActionsPopup,
    showPlayerActionsPopup,
    translateStat,
    changePlayerPosition,
    playerEntityClicked,
    teamEntityClicked,
    configAutoActions,
    autoActionLabel,
    autoActionColor,
    getCurrentTimeFromTimerApi,
    timerChange,
    toggleTime,
    translateLog,
    keyDownListener,
    clickTypeButton,
    selectEntity,
    selectType,
    acceptLog,
    gameUpdate,
    checkForNotifications,
    checkTeamNotifications,
    checkPlayerNotifications,
    deleteLog,
    deleteGameLog,
    stripHtmlTags,
    deleteLastLog,
    resetLog,
    getStateStatType,
    setPeriod,
    gameUpdatePeriod,
    setData,
    endGame,
    onInit,
    onDestroy,
    saveTimeToTimerApi,
    activeStopwatch,
    ejectTimer,
    gameLogColor
  }
}
