<template>
<div class="table-responsive">
  <table class="table table-bordered table-striped table-vcenter">
      <thead>
        <tr>
          <th v-for="column in showColumns" :key="column.id" :class="columnClass(column)">
            {{ column.name ? $t('table.column.' + column.name) : ''}}
          </th>
          <th v-if="$scopedSlots.actions" class="text-center"
            style="width: 100px;">{{$t('table.column.actions')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in data.data">
          <td v-for="column in showColumns" :key="column.id" :class="columnClass(column)">
            <span v-if="!['bool', 'img', 'link', 'clipboard', 'entity', 'option', 'options'].includes(column.type)" v-html="showInTable(item, column.prop, column.def)"></span>
            <span v-if="column.type === 'entity'">{{$tc(showInTable(item, column.prop, column.def) + '.name', 1)}}</span>
            <span v-if="column.type === 'option'">{{showInTable(item, column.prop, column.def) ? $t('select.options.' + showInTable(item, column.prop, column.def)) : ''}}</span>
            <template v-if="column.type === 'options'">
              <span class="badge badge-primary mr-1" v-for="(option, o) in showInTable(item, column.prop, column.def)" :key="`${option}-${o}`">
                {{ $t('select.options.' + option) }}
              </span>
            </template>
            <a v-if="column.type === 'link'" :href="'http://' + showInTable(item, column.prop, column.def)" target="_blank">{{showInTable(item, column.prop, column.def)}}</a>
            <button v-if="column.type === 'clipboard'" class="btn btn-info d-flex justify-content-between align-items-center" @click="copyToClipboard(showInTable(item, column.prop, column.def))"> <i :class="column.icon ? column.icon : 'fa fa-clipboard'"></i></button>
            <img v-if="column.type === 'img' && showInTable(item, column.prop, column.def)" :src="showInTable(item, column.prop, column.def)" class="img-avatar img-avatar32" alt="">
          </td>
          <td v-if="$scopedSlots.actions" class="text-center">
            <div class="btn-group">
              <slot :row="item" name="actions"></slot>
            </div>
          </td>
        </tr>
      </tbody>
  </table>
  <ul v-if="data.meta?.last_page" class="pagination">
    <li class="page-item" :class="{disabled: data.meta.current_page === 1}">
      <a class="page-link" @click="setPage(data.meta.current_page - 1)" href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li v-for="page in data.meta.last_page" :key="'page-' + page" :class="{active: data.meta.current_page === page}" class="page-item"><a @click="setPage(page)" class="page-link" href="#">{{ page }}</a></li>
    <li class="page-item" :class="{disabled: data.meta.current_page === data.meta.last_page}">
      <a class="page-link" @click="setPage(data.meta.current_page + 1)" href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</div>
</template>

<script>
// import ApiService from '@/services/api.service'

import { ToastMessage } from '@/lib/toast'

export default {
  props: {
    entityName: {
      type: String,
      default: 'Table'
    },
    data: Object,
    pagination: Object,
    showColumns: Array
  },
  data () {
    return {
      search: ''
    }
  },
  name: 'ntmTable',
  mounted () {
    // this.fetchData()
  },
  watch: {
    search: function () {
      this.fetchData()
    }
  },
  methods: {
    copyToClipboard (text) {
      this.$copyText(text).then(() => {
        ToastMessage({
          title: 'Copied to clipboard',
          icon: 'success'
        })
      })
    },
    columnClass (column) {
      return {
        'text-center': this.centerColumn(column) || column.align === 'center',
        'text-right': column.align === 'right',
        'text-left': column.align === 'left',
        'column-img': column.type === 'img'
      }
    },
    centerColumn (column) {
      return column.type === 'bool'
    },
    showInTable (item, prop, def) {
      if (item === undefined || item === null) {
        return def || ''
      }
      const index = prop.indexOf('.')
      if (index > -1) {
        return this.showInTable(item[prop.substring(0, index)], prop.substr(index + 1), def)
      }

      return item[prop]
    },
    setPage (page) {
      this.$emit('pageChange', page)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
li.disabled a {
  pointer-events: none;
}
</style>
