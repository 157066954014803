<template>
  <div class="pb-3">
    <div class="text-center">
      <div class="file-drop-area">
        <div class="file-wrapper mb-3 position-relative">
          <div v-if="loading" class="file-overlay">
            <span class="upload-cog"><i class="fa fa-cog fa-spin"></i></span>
          </div>
          <input v-if="!loading" class="file-input" type="file" ref="file" @change="loadImage($event)" accept="image/*">
          <img class="crop-image" :src="imageUrl || '/no-image.png'" alt="">
        </div>
      </div>
      <button type="button" v-if="imageUrl" @click="deleteImage()" class="btn btn-danger w-100 mb-3">{{
          $t('delete')
        }}
      </button>
    </div>
      <modal :name="`crop-modal-${_uid}`" :adaptive="true" height="auto">
        <cropper
          v-if="image.src"
          ref="cropper"
          :stencil-component="circle ? 'circle-stencil' : 'rectangle-stencil'"
          :canvas="{
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight,
        }"
          :stencil-props="{
          aspectRatio: this.aspectRatio,
        }"
          :src="image.src"
        />
        <div class="p-3 d-flex crop-actions">
          <button type="button" v-if="image.src" class="btn btn-success" @click="uploadImage">
            {{ $t('file.cropAndSave') }}
          </button>
          <button type="button" v-if="image.src" class="btn btn-warning" @click="reset">
            <i class="fa fa-undo"></i>
          </button>
        </div>
      </modal>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import apiService from '../../services/api.service'
import MediaService from '../../services/media.service'
import { ToastMessage } from '@/lib/toast'

export default {
  components: {
    Cropper
  },
  props: {
    aspectRatio: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    maxWidth: {
      type: Number,
      default: 500
    },
    entityType: {
      type: String,
      default: ''
    },
    entityUuid: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    },
    conversion: {
      type: String,
      default: null
    },
    circle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      imageUrl: '',
      image: {
        src: null,
        type: null
      }
    }
  },
  mounted () {
    this.fetchImage()
  },
  methods: {
    defaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'png'
        case '47494638':
          return 'gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'jpg'
        default:
          return fallback
      }
    },
    reset () {
      this.image = {
        src: null,
        type: null
      }
    },
    uploadImage () {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        this.loading = true
        const imageType = this.image.type
        this.$modal.hide(`crop-modal-${this._uid}`)
        this.image = {
          src: null,
          type: null
        }

        const form = new FormData()
        canvas.toBlob(blob => {
          form.append('file', blob, `blob.${imageType}`)
          form.append('type', this.collection)
          form.append('uuid', this.entityUuid)
          form.append('assetType', this.entityType)
          apiService.post(`${process.env.VUE_APP_BASE_URL}api/v1/admin/media/upload`, form).then(() => {
            ToastMessage({
              title: this.$t('image.uploaded'),
              icon: 'success'
            })
            this.fetchImage()
          }).catch(() => {
            this.loading = false
          })
          // You can use axios, superagent and other libraries instead here

          // Perhaps you should add the setting appropriate file format here
        }, `image/${imageType}`)
      }
    },
    loadImage (event) {
      // Reference to the DOM input element
      const { files } = event.target
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //

        // Create a new FileReader to read this image binary data
        const reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, files[0].type)
          }
        }

        reader.onloadend = (e) => {
          this.$modal.show(`crop-modal-${this._uid}`)
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0])
      }
    },
    fetchImage () {
      this.loading = true
      MediaService.getFirstImageByType(this.entityType, this.entityUuid, this.collection, this.conversion).then((res) => {
        this.imageUrl = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    deleteImage () {
      MediaService.deleteByType(this.entityType, this.entityUuid, this.collection).then(() => {
        ToastMessage({
          title: this.$t('image.deleted'),
          icon: 'success'
        })
        this.fetchImage()
      })
    }
  },
  destroyed () {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  }
}
</script>
<style lang="scss">
.file-drop-area {
  position: relative;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: 0.2s;

  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.file-wrapper {
  border: 2px solid transparent;
  transition: 0.2s ease-in;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #5c80d1;
  }
}

.file-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-actions {
  gap: 16px;
}

.crop-image {
  max-width: 400px;
  width: 100%;
}

.upload-cog {
  font-size: 26px;
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  font-weight: 700;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  &:focus {
    outline: none;
  }
}
</style>
