var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row align-items-end"},[(_vm.config)?_c('div',{staticClass:"col-sm-4 team-table",class:[_vm.homeTeamOrderClass, {
        'home-team': _vm.teamSelect === 'home'
      }]},[_c('div',{staticClass:"team-period-stats-wrapper mb-4"},[_c('div',{staticClass:"d-flex align-items-center stat-wrapper"},[_c('span',{staticClass:"w-100 stat-title"},[_vm._v(_vm._s(_vm.$t('period')))]),_vm._l((_vm.config.periods),function(tableHeader){return _c('span',{key:'awayTeamByPeriod-' + tableHeader,staticClass:"stat"},[_vm._v(" "+_vm._s(tableHeader)+" ")])}),(_vm.model.homeTeam?.by_period_stats?.length > _vm.config.periods)?_c('span',{staticClass:"stat"},[_vm._v(" OT ")]):_vm._e()],2),_vm._l((_vm.config.live.by_period.team_table),function(tableBody){return _c('div',{key:'awayTeamByPeriod-' + tableBody,staticClass:"d-flex align-items-center stat-wrapper"},[_c('span',{staticClass:"w-100 stat-title"},[_vm._v(_vm._s(_vm.$t(`live.${tableBody}`)))]),_vm._l((_vm.model.homeTeam.by_period_stats),function(period,p){return _c('span',{key:'awayTeamByPeriod-' + p,staticClass:"stat"},[_vm._v(" "+_vm._s(period ? period[tableBody] : '-')+" ")])})],2)})],2)]):_vm._e(),(_vm.config)?_c('div',{staticClass:"col-sm-4 live-buttons",class:[_vm.actionsOrderClass]},[_c('nav',{attrs:{"aria-label":"Page navigation"}},[_c('ul',{staticClass:"periods pagination pagination-lg justify-content-center"},[_vm._l((_vm.config.periods),function(period){return _c('li',{key:'period_' + period,staticClass:"page-item",class:{active: _vm.model.current_period === period}},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.setPeriod(period)}}},[_vm._v(_vm._s(period))])])}),_c('li',{staticClass:"page-item",class:{active: _vm.model.current_period === 0}},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.setPeriod(0)}}},[_vm._v("OT")])])],2)]),_c('h4',{staticClass:"text-center"},[_vm._v("("+_vm._s(_vm.scoreByPeriod)+")")]),_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.score))])]):_vm._e(),(_vm.config)?_c('div',{staticClass:"col-sm-4 team-table",class:[_vm.awayTeamOrderClass, {
        'away-team': _vm.teamSelect === 'away'
      }]},[_c('div',{staticClass:"team-period-stats-wrapper mb-4"},[_c('div',{staticClass:"d-flex align-items-center stat-wrapper"},[_c('span',{staticClass:"w-100 stat-title"},[_vm._v(_vm._s(_vm.$t('period')))]),_vm._l((_vm.config.periods),function(tableHeader){return _c('span',{key:'awayTeamByPeriod-' + tableHeader,staticClass:"stat"},[_vm._v(" "+_vm._s(tableHeader)+" ")])}),(_vm.model.awayTeam.by_period_stats?.length > _vm.config.periods)?_c('span',{staticClass:"stat"},[_vm._v(" OT ")]):_vm._e()],2),_vm._l((_vm.config.live.by_period.team_table),function(tableBody){return _c('div',{key:'awayTeamByPeriod-' + tableBody,staticClass:"d-flex align-items-center stat-wrapper"},[_c('span',{staticClass:"w-100 stat-title"},[_vm._v(_vm._s(_vm.$t(`live.${tableBody}`)))]),_vm._l((_vm.model.awayTeam.by_period_stats),function(period,p){return _c('span',{key:'awayTeamByPeriod-' + p,staticClass:"stat"},[_vm._v(" "+_vm._s(period ? period[tableBody] : '-')+" ")])})],2)})],2)]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.config)?_c('div',{staticClass:"col-sm-4 team-table pt-0 d-flex flex-column",class:[_vm.homeTeamOrderClass, {
        'home-team': _vm.teamSelect === 'home',
      }]},[_c('h3',{staticClass:"text-center unselectable w-100"},[_c('a',{directives:[{name:"longpress",rawName:"v-longpress",value:((event) => _vm.teamEntityClicked(event, 'homeTeam', _vm.model.homeTeam)),expression:"(event) => teamEntityClicked(event, 'homeTeam', model.homeTeam)"}],staticClass:"btn",class:{'btn-success': _vm.relatedTo === 'team' && _vm.teamOn === 'home'},staticStyle:{"width":"100%"},style:({ background: _vm.homeTeamColor }),on:{"click":function($event){return _vm.selectEntity('team', 'home', _vm.model.homeTeam.uuid)},"contextmenu":function($event){$event.preventDefault();return _vm.teamEntityClicked($event, 'homeTeam', _vm.model.homeTeam)}}},[_vm._v(" "+_vm._s(_vm.model.homeTeam.name)+" ")])]),_c('div',{staticClass:"d-flex w-100",class:{'flex-grow-1 align-items-center': _vm.model.homeTeam.players?.length}},[_c('ntm-block',{staticClass:"w-100"},[_c('div',{staticClass:"table-responsive"},[(_vm.teamSelect === 'home')?_c('div',{staticClass:"select-player-wrapper"},[_c('h3',{staticClass:"text-white mt-5"},[_vm._v(_vm._s(_vm.playerSelect))]),_c('h3',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.playerPreview))])]):_vm._e(),(!_vm.model.homeTeam.players?.length)?_c('div',{staticClass:"row w-100 team-actions"},_vm._l((_vm.buttonTeamGroups),function(button,n){return _c('div',{key:'buttonTeamGroups-' + n,staticClass:"action-wrap",class:[button.class || 'half']},[_c('a',{staticClass:"btn action-button position-relative",class:{
              'disabled': _vm.log.teamOn === '' || _vm.relatedTo !== 'team',
              'active': _vm.clickedButton === n,
              'has-label': button.hotKey?.label
              },style:({backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}),attrs:{"disabled":_vm.log.teamOn === '' || _vm.relatedTo !== 'team'},on:{"click":function($event){return _vm.selectType(n)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('live.sports.' + _vm.config.sport + '.game_log_buttons.team.' + n)))]),(button.hotKey?.label)?_c('span',{staticClass:"hot-key-label"},[_vm._v(_vm._s(button.hotKey?.label))]):_vm._e()])])}),0):_vm._e(),(_vm.model.homeTeam && _vm.model.homeTeam.players.length > 0)?_c('table',{staticClass:"table player-live-table table-hover table-vcenter"},[_c('thead',[_c('tr',[(_vm.reversedTeams || !_vm.invertedTable)?_c('th',{staticClass:"text-center",class:{
                    'right-table-sticky': (!_vm.invertedTable && !_vm.reversedTeams) || (_vm.reversedTeams),
                    'left-table-sticky': _vm.invertedTable && !_vm.reversedTeams
                  }},[_vm._v(" "+_vm._s(_vm.$t('live.table.jersey'))+" ")]):_vm._e(),_vm._l((_vm.config.live.player_table),function(column,key){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(!_vm.reversedTeams && _vm.invertedTable),expression:"!reversedTeams && invertedTable"}],key:'reversed' + column,staticClass:"text-center"},[_vm._v(" "+_vm._s(key)+" ")])}),_c('th',{staticClass:"name-col",class:{
                    'right-table-sticky': (!_vm.invertedTable && !_vm.reversedTeams) || (_vm.reversedTeams),
                    'left-table-sticky': _vm.invertedTable && !_vm.reversedTeams
                  }},[_vm._v(" "+_vm._s(_vm.$t('live.table.name'))+" ")]),_vm._l((_vm.config.live.player_table),function(column,key){return _c('th',{directives:[{name:"show",rawName:"v-show",value:((!_vm.reversedTeams && !_vm.invertedTable) || (_vm.reversedTeams)),expression:"(!reversedTeams && !invertedTable) || (reversedTeams)"}],key:'normal' + column,staticClass:"text-center"},[_vm._v(" "+_vm._s(key)+" ")])}),(!_vm.reversedTeams && _vm.invertedTable)?_c('th',{staticClass:"text-center",class:{
                    'right-table-sticky': _vm.reversedTeams,
                    'left-table-sticky': _vm.invertedTable
                  }},[_vm._v(" "+_vm._s(_vm.$t('live.table.jersey'))+" ")]):_vm._e()],2)]),_c('tbody',{class:{'disabled': _vm.disableHome}},_vm._l((_vm.model.homeTeam.players),function(player){return _c('tr',{directives:[{name:"longpress",rawName:"v-longpress",value:((event) => _vm.playerEntityClicked(event, 'homeTeam', player)),expression:"(event) => playerEntityClicked(event, 'homeTeam', player)"}],key:player.uuid,staticClass:"player-row unselectable",class:{'bg-success': _vm.log.on === player.uuid,
                  'bg-info': _vm.log.teammate === player.uuid,
                  'bg-warning': _vm.log.against === player.uuid},style:({color: _vm.autoActionColor('homeTeam', player)}),on:{"contextmenu":function($event){$event.preventDefault();return _vm.playerEntityClicked($event, 'homeTeam', player)},"click":function($event){return _vm.selectEntity('player', 'home', player.uuid)}}},[(_vm.reversedTeams || !_vm.invertedTable)?_c('td',{staticClass:"font-w600 text-center number-of-jersey",class:{
                      'bg-success': _vm.log.on === player.uuid,
                      'bg-info': _vm.log.teammate === player.uuid,
                      'bg-warning': _vm.log.against === player.uuid,
                      'right-table-sticky': (!_vm.invertedTable && !_vm.reversedTeams) || (_vm.reversedTeams),
                      'left-table-sticky': _vm.invertedTable && !_vm.reversedTeams
                    }},[_vm._v(" "+_vm._s(_vm.autoActionLabel('homeTeam', player) ? _vm.autoActionLabel('homeTeam', player) : player.number_of_jersey)+" ")]):_vm._e(),_vm._l((_vm.config.live.player_table),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.reversedTeams && _vm.invertedTable),expression:"!reversedTeams && invertedTable"}],key:'reversed' + column + '-' + player.uuid,staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(player[column])+" ")])}),_c('td',{staticClass:"font-w600 name-col",class:{
                      'bg-success': _vm.log.on === player.uuid,
                      'bg-info': _vm.log.teammate === player.uuid,
                      'bg-warning': _vm.log.against === player.uuid,
                      'right-table-sticky pr-2': (!_vm.invertedTable && !_vm.reversedTeams) || (_vm.reversedTeams),
                      'left-table-sticky pl-2': _vm.invertedTable && !_vm.reversedTeams
                    }},[_vm._v(_vm._s(player.name)+" ")]),_vm._l((_vm.config.live.player_table),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:((!_vm.reversedTeams && !_vm.invertedTable) || (_vm.reversedTeams)),expression:"(!reversedTeams && !invertedTable) || (reversedTeams)"}],key:'normal' + column + '-' + player.uuid,staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(player[column])+" ")])}),(!_vm.reversedTeams && _vm.invertedTable)?_c('td',{staticClass:"font-w600 text-center number-of-jersey",class:{
                      'bg-success': _vm.log.on === player.uuid,
                      'right-table-sticky': !_vm.invertedTable,
                      'left-table-sticky': _vm.invertedTable
                    }},[_vm._v(" "+_vm._s(_vm.autoActionLabel('homeTeam', player) ? _vm.autoActionLabel('homeTeam', player) : player.number_of_jersey)+" ")]):_vm._e()],2)}),0)]):_vm._e()])])],1)]):_vm._e(),(_vm.config)?_c('div',{staticClass:"col-sm-4 live-buttons mb-5 d-flex flex-column",class:[_vm.actionsOrderClass]},[(_vm.model.homeTeam.players?.length && _vm.model.awayTeam.players?.length)?_c('div',{staticClass:"row w-100 team-actions"},_vm._l((_vm.buttonTeamGroups),function(button,n){return _c('div',{key:'buttonTeamGroups-' + n,staticClass:"action-wrap",class:[button.class || 'half']},[_c('a',{staticClass:"btn action-button position-relative",class:{
              'disabled': _vm.log.teamOn === '' || _vm.relatedTo !== 'team',
              'active': _vm.clickedButton === n,
              'has-label': button.hotKey?.label
              },style:({backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}),attrs:{"disabled":_vm.log.teamOn === '' || _vm.relatedTo !== 'team'},on:{"click":function($event){return _vm.selectType(n)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('live.sports.' + _vm.config.sport + '.game_log_buttons.team.' + n)))]),(button.hotKey?.label)?_c('span',{staticClass:"hot-key-label"},[_vm._v(_vm._s(button.hotKey?.label))]):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"d-flex flex-grow-1 align-items-center"},[_c('div',{staticClass:"row w-100 players-actions"},_vm._l((_vm.buttonPlayerGroups),function(buttonGroup,n){return _c('div',{key:'buttonPlayerGroup-' + n,staticClass:"col-sm-12 pb-3"},[_c('div',{staticClass:"row"},_vm._l((buttonGroup),function(button,buttonType){return _c('div',{key:'buttonPlayerTypes-' + buttonType,staticClass:"action-wrap",class:[button.class || 'half']},[_c('a',{staticClass:"btn action-button position-relative",class:{
                    'disabled': _vm.log.teamOn === '' || _vm.relatedTo !== 'player' || (_vm.type !== buttonType && _vm.type !== ''),
                    'active': _vm.clickedButton === buttonType,
                    'has-label': button.hotKey?.label
                    },style:({backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}),attrs:{"disabled":_vm.log.on === '' || _vm.relatedTo !== 'player'},on:{"click":function($event){return _vm.selectType(buttonType)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('live.sports.' + _vm.config.sport + '.game_log_buttons.player.' + buttonType)))]),(button.hotKey?.label)?_c('span',{staticClass:"hot-key-label"},[_vm._v(_vm._s(button.hotKey?.label))]):_vm._e()])])}),0)])}),0)])]):_vm._e(),(_vm.config)?_c('div',{staticClass:"col-sm-4 team-table pt-0 d-flex flex-column",class:[_vm.awayTeamOrderClass, {
        'away-team': _vm.teamSelect === 'away',
      }]},[_c('h3',{staticClass:"text-center unselectable w-100"},[_c('a',{directives:[{name:"longpress",rawName:"v-longpress",value:((event) => _vm.teamEntityClicked(event, 'awayTeam', _vm.model.awayTeam)),expression:"(event) => teamEntityClicked(event, 'awayTeam', model.awayTeam)"}],staticClass:"btn",class:{'btn-success': _vm.relatedTo === 'team' && _vm.teamOn === 'away'},staticStyle:{"width":"100%"},style:({ background: _vm.awayTeamColor }),on:{"click":function($event){return _vm.selectEntity('team', 'away', _vm.model.awayTeam.uuid)},"contextmenu":function($event){$event.preventDefault();return _vm.teamEntityClicked($event, 'awayTeam', _vm.model.awayTeam)}}},[_vm._v(_vm._s(_vm.model.awayTeam.name)+" ")])]),_c('div',{staticClass:"d-flex w-100",class:{'flex-grow-1 align-items-center': _vm.model.awayTeam.players?.length}},[_c('ntm-block',{staticClass:"w-100"},[_c('div',{staticClass:"table-responsive"},[(_vm.teamSelect === 'away')?_c('div',{staticClass:"select-player-wrapper"},[_c('h3',{staticClass:"text-white mt-5"},[_vm._v(_vm._s(_vm.playerSelect))]),_c('h3',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.playerPreview))])]):_vm._e(),(!_vm.model.awayTeam.players?.length)?_c('div',{staticClass:"row w-100 team-actions"},_vm._l((_vm.buttonTeamGroups),function(button,n){return _c('div',{key:'buttonTeamGroups-' + n,staticClass:"action-wrap",class:[button.class || 'half']},[_c('a',{staticClass:"btn action-button position-relative",class:{
              'disabled': _vm.log.teamOn === '' || _vm.relatedTo !== 'team',
              'active': _vm.clickedButton === n,
              'has-label': button.hotKey?.label
              },style:({backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}),attrs:{"disabled":_vm.log.teamOn === '' || _vm.relatedTo !== 'team'},on:{"click":function($event){return _vm.selectType(n)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('live.sports.' + _vm.config.sport + '.game_log_buttons.team.' + n)))]),(button.hotKey?.label)?_c('span',{staticClass:"hot-key-label"},[_vm._v(_vm._s(button.hotKey?.label))]):_vm._e()])])}),0):_vm._e(),(_vm.model.awayTeam && _vm.model.awayTeam.players.length > 0)?_c('table',{staticClass:"table player-live-table table-hover table-vcenter",class:!_vm.reversedTeams ? 'right-table' : 'left-table'},[_c('thead',[_c('tr',[(!_vm.reversedTeams || !_vm.invertedTable)?_c('th',{staticClass:"text-center right-table-sticky"},[_vm._v(" "+_vm._s(_vm.$t('live.table.jersey'))+" ")]):_vm._e(),_vm._l((_vm.config.live.player_table),function(column,key){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.reversedTeams && _vm.invertedTable),expression:"reversedTeams && invertedTable"}],key:'reversed' + column,staticClass:"text-center"},[_vm._v(" "+_vm._s(key)+" ")])}),_c('th',{staticClass:"name-col",class:{
                    'right-table-sticky': (!_vm.reversedTeams) || (_vm.reversedTeams && !_vm.invertedTable),
                    'left-table-sticky': _vm.invertedTable && _vm.reversedTeams
                  }},[_vm._v(" "+_vm._s(_vm.$t('live.table.name'))+" ")]),_vm._l((_vm.config.live.player_table),function(column,key){return _c('th',{directives:[{name:"show",rawName:"v-show",value:((!_vm.reversedTeams) || (_vm.reversedTeams && !_vm.invertedTable)),expression:"(!reversedTeams) || (reversedTeams && !invertedTable)"}],key:'normal' + column,staticClass:"text-center"},[_vm._v(" "+_vm._s(key)+" ")])}),(_vm.reversedTeams && _vm.invertedTable)?_c('th',{staticClass:"text-center",class:{
                    'right-table-sticky': (!_vm.invertedTable && !_vm.reversedTeams) || (_vm.reversedTeams),
                    'left-table-sticky': _vm.invertedTable && _vm.reversedTeams
                  }},[_vm._v(" "+_vm._s(_vm.$t('live.table.jersey'))+" ")]):_vm._e()],2)]),_c('tbody',{class:{'disabled': _vm.disableAway}},_vm._l((_vm.model.awayTeam.players),function(player){return _c('tr',{directives:[{name:"longpress",rawName:"v-longpress",value:((event) => _vm.playerEntityClicked(event, 'awayTeam', player)),expression:"(event) => playerEntityClicked(event, 'awayTeam', player)"}],key:player.uuid,staticClass:"player-row unselectable",class:{'bg-success': _vm.log.on === player.uuid,
                'bg-info': _vm.log.teammate === player.uuid,
                'bg-warning': _vm.log.against === player.uuid},style:({color: _vm.autoActionColor('awayTeam', player)}),on:{"contextmenu":function($event){$event.preventDefault();return _vm.playerEntityClicked($event, 'awayTeam', player)},"click":function($event){return _vm.selectEntity('player', 'away', player.uuid)}}},[(!_vm.reversedTeams || !_vm.invertedTable)?_c('td',{staticClass:"right-table-sticky font-w600 text-center number-of-jersey",class:{
                      'bg-success': _vm.log.on === player.uuid,
                      'bg-info': _vm.log.teammate === player.uuid,
                      'bg-warning': _vm.log.against === player.uuid
                    }},[_vm._v(_vm._s(_vm.autoActionLabel('awayTeam', player) ? _vm.autoActionLabel('awayTeam', player) : player.number_of_jersey)+" ")]):_vm._e(),_vm._l((_vm.config.live.player_table),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.reversedTeams && _vm.invertedTable),expression:"reversedTeams && invertedTable"}],key:'reversed' + column + player.uuid,staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(player[column])+" ")])}),_c('td',{staticClass:"font-w600 name-col",class:{
                    'bg-success': _vm.log.on === player.uuid,
                    'bg-info': _vm.log.teammate === player.uuid,
                    'bg-warning': _vm.log.against === player.uuid,
                    'right-table-sticky pr-2': (!_vm.reversedTeams) || (_vm.reversedTeams && !_vm.invertedTable),
                    'left-table-sticky pl-2': _vm.invertedTable && _vm.reversedTeams
                  }},[_vm._v(_vm._s(player.name)+" ")]),_vm._l((_vm.config.live.player_table),function(column){return _c('td',{directives:[{name:"show",rawName:"v-show",value:((!_vm.reversedTeams) || (_vm.reversedTeams && !_vm.invertedTable)),expression:"(!reversedTeams) || (reversedTeams && !invertedTable)"}],key:'normal' + column + player.uuid,staticClass:"text-muted text-center"},[_vm._v(" "+_vm._s(player[column])+" ")])}),(_vm.reversedTeams && _vm.invertedTable)?_c('td',{staticClass:"font-w600 text-center number-of-jersey",class:{
                      'bg-success': _vm.log.on === player.uuid,
                      'right-table-sticky': !_vm.reversedTeams,
                      'left-table-sticky': _vm.reversedTeams
                    }},[_vm._v(_vm._s(_vm.autoActionLabel('awayTeam', player) ? _vm.autoActionLabel('awayTeam', player) : player.number_of_jersey)+" ")]):_vm._e()],2)}),0)]):_vm._e()])])],1)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 text-center action-wrapper mt-10"},[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.endGame()}}},[_vm._v(" "+_vm._s(_vm.$t('game.endGame'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }