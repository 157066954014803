/* eslint-disable */
// import env from '@/env'
// import * as VueGoogleMaps from 'vue2-google-maps'
import VueFormly from './formly/vue-formly'
import VueFormlyBootstrap from './formly/formly-bootstrap'
import Color from './formly/fields/Color'
import Datetime from './formly/fields/Datepicker'
import Multiselect from './formly/fields/Multiselect'
import Multicheck from './formly/fields/Multicheck'
import Boolean from './formly/fields/Boolean'
import HtmlEditor from './formly/fields/HtmlEditor'
import Upload from './formly/fields/Upload'
import NtmTable from './ntm/ntmTable'
import datePicker from 'vue-ctk-date-time-picker'
import NtmList from '@/components/ntm/list/ntmList'
import NtmListItem from '@/components/ntm/list/ntmListItem'
import ntmModal from '@/components/ntm/ntmModal'
import ntmBlock from '@/components/ntm/ntmBlock'
import VueCarousel from '@/components/ntm/carousel'
import VueDraggable from 'vuedraggable'
import PageHeader from './page/PageHeader'
import PageContent from './page/PageContent'
import vSelect from 'vue-select'
import ntmSidebar from '@/components/ntm/ntmSidebar'
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2';
import ntmUpload from "./ntm/ntmUpload";
import ntmCrop from "./ntm/ntmCrop";
import {CircleStencil} from "vue-advanced-cropper";
import LinearColor from "./formly/fields/LinearColor";
import VueClipboard from 'vue-clipboard2'
import VModal from 'vue-js-modal'
import LeQRcode from "@/components/le/LeQRcode.vue";
import LeCopyToClipboard from "@/components/le/LeCopyToClipboard.vue";
import NtmSelect from "@/components/formly/fields/NtmSelect.vue";
import Debt from "@/components/shared/Debt.vue";
import DropdownMenu from '@innologica/vue-dropdown-menu';
import Notifications from 'vue-notification'

const RegisterPlugin = {
  install (Vue) {
    VueFormly.addType('circle-stencil', CircleStencil)
    VueFormly.addType('linear-color', LinearColor)
    VueFormly.addType('color', Color)
    VueFormly.addType('datepicker', Datetime)
    VueFormly.addType('ntm-select', Multiselect)
    VueFormly.addType('ntm-multicheck', Multicheck)
    VueFormly.addType('upload', Upload)
    VueFormly.addType('boolean', Boolean)
    VueFormly.addType('html', HtmlEditor)
    VueFormly.addType('ntm-v-select', NtmSelect)


    Vue.use(VueClipboard)
    Vue.use(VueFormly)
    Vue.use(VueFormlyBootstrap)
    Vue.use(VueCarousel)
    Vue.use(VueDraggable)
    Vue.use(VueSweetalert2)
    Vue.use(VModal)
    Vue.component('le-copy-to-clipboard', LeCopyToClipboard)
    Vue.component('le-qr-code', LeQRcode)
    Vue.component('ntm-crop', ntmCrop)
    Vue.component('ntm-sidebar', ntmSidebar)
    Vue.component('ntm-dropdown', DropdownMenu)
    // Vue.component('ntm-html-editor', VueEditor)
    Vue.component('ntm-table', NtmTable)
    Vue.component('ntm-list', NtmList)
    Vue.component('ntm-list-item', NtmListItem)
    Vue.component('page-header', PageHeader)
    Vue.component('page-content', PageContent)
    Vue.component('date-picker', datePicker)
    Vue.component('ntm-modal', ntmModal)
    Vue.component('ntm-block', ntmBlock)
    Vue.component('ntm-upload', ntmUpload)
    Vue.component('v-select', vSelect)
    Vue.component('ntm-select', Multiselect)
    Vue.component('debt', Debt)

    // Use Notify
    Vue.use(Notifications, {
      componentName: 'log-notifications'
    })

    Vue.directive('longpress', {
      bind: function (el, binding, vNode) {
        // Make sure expression provided is a function
        if (typeof binding.value !== 'function') {
          // Fetch name of component
          const compName = vNode.context.name
          // pass warning to console
          let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}' ` }

          console.warn(warn)
        }

        // Define variable
        let pressTimer = null

        // Define funtion handlers
        // Create timeout ( run function after 1s )
        let start = (e) => {

          if (e.type === 'click' && e.button !== 0) {
            return;
          }

          if (pressTimer === null) {
            pressTimer = setTimeout(() => {
              // Run function
              handler()
            }, 500)
          }
        }

        // Cancel Timeout
        let cancel = (e) => {
          // Check if timer has a value or not
          if (pressTimer !== null) {
            clearTimeout(pressTimer)
            pressTimer = null
          }
        }
        // Run Function
        const handler = (e) => {
          binding.value(e)
        }

        // Add Event listeners
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        // Cancel timeouts if this events happen
        el.addEventListener("touchmove", cancel);
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
      }
    })
    // Vue.use(VueGoogleMaps, {
    //   load: {
    //     key: env.googleAPI,
    //     libraries: 'places', // This is required if you use the Autocomplete plugin
    //     // OR: libraries: 'places,drawing'
    //     // OR: libraries: 'places,drawing,visualization'
    //     // (as you require)

    //     //// If you want to set the version, you can do so:
    //     // v: '3.26',
    //   },

    //   //// If you intend to programmatically custom event listener code
    //   //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //   //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //   //// you might need to turn this on.
    //   // autobindAllEvents: false,

    //   //// If you want to manually install components, e.g.
    //   //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //   //// Vue.component('GmapMarker', GmapMarker)
    //   //// then disable the following:
    //   // installComponents: true,

    // })
  }
}

export default RegisterPlugin
